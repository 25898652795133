// DO NOT EDIT THIS FILE !!!! GENERATED DURING BUILD TYPE : Ansible
export const DOL_VERSION='[master]6.1-10718(465dc11ee9)-C10/10/2024-16:48:00-B10/10/2024-16:53:13' ;
export const DOL_VERSION_JSON = {
  dolVersion: "[master]6.1-10718(465dc11ee9)-C10/10/2024-16:48:00-B10/10/2024-16:53:13",
  branch: "master",
  latestTag: "6.1",
  revCount: "10718",
  shortHash: "465dc11ee9",
  longHash: "465dc11ee945dee209bbec4baadcebbff9155e28",
  dateCommit: "10/10/2024-16:48:00",
  dateBuild: "10/10/2024-16:53:13",
  buildType: "Ansible",
  } ;
